import React from 'react'
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCoffee, faEnvelope, faFaceAngry, faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"


const Footer = () => {
  return (
    <footer>
      <div className='footer__items'>
        <p>The Functional<br></br>Nurse Practitioner</p>
        <ul>
          <li className='changeFooterColor'><FontAwesomeIcon icon={faPhone} /><a href="tel:9303331435">930-333-1435</a></li>
          <li className='changeFooterColor'><FontAwesomeIcon icon={faEnvelope} /><a href="mailto:info@thefunctionalnursepractitioner.com">info@thefunctionalnursepractitioner.com</a></li>
          <li><FontAwesomeIcon icon={faBuilding} />2815 E 3rd St #1052, Bloomington, IN 47401</li>
        </ul>
      </div>
      <div className='footer__socials'>
        <a href="https://www.facebook.com/thefunctionalnursepractitioner" target='_blank'>
          <p><FontAwesomeIcon icon={faFacebook} size='2x' /></p></a>
        <a href="https://www.instagram.com/thefunctionalnursepractitioner/" target='_blank'><p><FontAwesomeIcon icon={faInstagram} size='2x' /></p></a>
        <a href="https://www.youtube.com/channel/UCYoSkehh4-qfo1asa-DEKDg" target='_blank'><p><FontAwesomeIcon icon={faYoutube} size='2x' /></p></a>
      </div>
    </footer>
  )
}

export default Footer