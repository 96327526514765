import React from 'react'
import './About.css'
import HeaderGraphic from '../../components/HeaderGraphic/HeaderGraphic'
import circleTara from "../../images/circleTara.png"
import Footer from '../../components/Footer/Footer'

const About = () => {
  return (
    <div className='about__page__wrapper'>
        <div className='about__page__wrapper__wrapper'>
        <HeaderGraphic words="About Us." video="https://www.youtube.com/embed/nr9uRfL3BH8" tagline="Learn about Tara Quintana and her practice"/>
        <div className='general__page__content__wrapper'>
            <h2>Tara's Story</h2>

            <p>Tara Quintana is a Board Certified Family Nurse Practitioner and licensed in Indiana.  She completed her Master of Nursing Practice Degree at Purdue University Global, graduating with honors, while working as an RN in Monroe and surrounding counties.  She has ten years of experience as an RN, dedicated to providing personalized and compassionate care.</p>

            <div className='floating__image__right'>
                <img src={circleTara} className='floating__right__image' alt="tara smiling"/>
                <p>Working in Women’s Health, Tara provided exceptional care, focusing on wellness and disease prevention.  With the state of our national healthcare system, and the incredible need for personalized medicine, Tara felt compelled to go back to school and was accepted into The Institute of Functional Medicine.  The rigorous program was very challenging, and fueled Tara’s continued desire for evidence-based medicine, leading to the creation of The Functional Nurse Practitioner Podcast.</p>
                
                <p>Tara began incorporating functional medicine methodologies within her Women’s Health practice and the results were phenomenal.  She continued pushing the barriers of conventional medicine, until it became apparent that a truly personalized approach needed more.  Tara opened her own practice, The Functional Nurse Practitioner, LLC, and has not looked back.  Specializing in root cause medicine, Tara takes a different approach, unraveling each client’s unique story, in order to facilitate optimal health.  Tara’s podcast continues to provide exceptional education for her community, and her platform is highly regarded within the health and wellness space.  Tara has many plans for the future regarding educational, nutrition, and coaching programs, as wellness is at the forefront of her business.</p>
            </div>

            <p>Tara is an active member of several professional organizations including the American Academy of Nurse Practitioners, Oncology Nursing Society, and Alpha Beta Kappa, the National Honor Society, by election of the Delta Zeta Chapter.</p>

            <p>Tara and her husband Tom reside in Ellettsville, Indiana.  She enjoys cooking, fitness, mountain biking, and spending her free time traveling to both favorite and new bike destinations.</p>


            <h2>Our Practice</h2>
            <p>At our practice, we take a holistic approach to healthcare and focus on you as a whole person, not just your symptoms and diseases.  We understand that your health is impacted by a variety of factors, such as genetics, lifestyle, nutrition, and environment, and we take all of these into consideration when developing your personalized action plan.  We offer a unique approach to wellness, spending more time with our clients than traditional medical providers, so we can fully understand your health history, symptoms, and also experiences with other treatment plans in the past.</p>

            <p>During your initial visit, we will utilize the functional medicine Timeline and Matrix, unraveling your unique story.  These discovery tools help to guide our pathway as we embark on a journey towards wellness, which is tailored to your specific circumstances and needs.  We understand that our consultations are not intended to replace appointments or relationships with primary care or specialty practitioners, so we partner with you and your current care providers to ensure that you receive the best care possible.</p>

            </div>
           
        </div>
        <Footer />
    </div>
  )
}

export default About