import React from 'react'
import './HeaderGraphic.css'

const HeaderGraphic = (props) => {
  return (
    <div className='header__graphic'>
        <h1>{props.words}</h1>
        <p>{props.tagline}</p>
        <div className='video__container'>
          <iframe src={props.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

    </div>
  )
}

export default HeaderGraphic