import React from 'react'
import './Navbar.css'
import logo from '../../images/logo.png'
import {Link } from "react-router-dom";
import { useState } from 'react';

const Navbar = () => {
  const [openNav, setOpenNav] = useState(false)
  return (
    <div id="main__navbar__wrapper">
      <nav id={"main__navbar"}>
        <Link to="/" className='logo-ref'><img src={logo} alt="the functional nurse practitioner logo" className='navbar__logo'></img></Link>
        <ul className={openNav ? 'navbar__links active' : 'navbar__links'}>
          <li><Link to="/" onClick={()=>setOpenNav(false)}>Home</Link></li>
          <li><Link to="/about" onClick={()=>setOpenNav(false)}>About</Link></li>
          <li><Link to="/what-is-functional-medicine" onClick={()=>setOpenNav(false)}>Our Approach</Link></li>
          <li><Link to="/membership" onClick={()=>setOpenNav(false)}>Membership</Link></li>
          <li><Link to="/podcast" onClick={()=>setOpenNav(false)}>Podcast</Link></li>
        
          <li><ul className='navbar__buttons'>
            <li><a href="https://www.optimantra.com/optimus/om/patient/login" target="_blank">Secure Client Portal</a></li>
            <li><a href='https://www.optimantra.com/optimus/patient/patientaccess/servicesall?mid=1023&pid=NWxtalFOa1M1ZDZwSTNwZ3AzY08vdz09&lid=TmNIZ3FKUFdhVm1YME1lQkMxUEE5QT09&hideFooterInFrame=&hideHeaderInFrame=' target="_blank">Book a free consultation</a></li>
          </ul>
          </li>
        </ul>
        <button className={openNav ? 'navbar__hide__open active' : 'navbar__hide__open'} onClick={()=>setOpenNav(!openNav)}>
          <span className='bar1'></span>
          <span className='bar2'></span>
          <span className='bar3'></span>
        </button>
      </nav>
    </div>
  )
}

export default Navbar