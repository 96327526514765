import React from 'react'
import './GlassCTA.css'
import tara from "../../images/tara.png"

const GlassCTA = () => {
  return (
    <div className='glass__cta'>
        <div className='glass__cta__text'>
            <h1>Your Wellness Journey<br></br>Starts <span className='underlined__cta__text'>Now</span></h1>
            <p>Do you suffer from a chronic disease? Are you tired of feeling bad? Has traditional medicine left you feeling lost? I am a board certified Family Nurse Practitioner who believes in the utilization of both conventional and functional medicine strategies in order to provide a more comprehensive approach for optimal health.</p>
            <a className='button__style__1' href='https://www.optimantra.com/optimus/patient/patientaccess/servicesall?mid=1023&pid=NWxtalFOa1M1ZDZwSTNwZ3AzY08vdz09&lid=TmNIZ3FKUFdhVm1YME1lQkMxUEE5QT09&hideFooterInFrame=&hideHeaderInFrame=' target='_blank'>Book a free consultation</a>
        </div>
        <div className='glass__cta__graphic'>
            <img src={tara} alt="tara and her husband tom smiling infront of mountains"></img>
        </div>
    </div>
  )
}

export default GlassCTA