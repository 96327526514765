import React from 'react'
import HeaderGraphic from '../../components/HeaderGraphic/HeaderGraphic'
import stats from '../../images/stats.png'
import Footer from '../../components/Footer/Footer'
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';

const WIFM = () => {
  return (

    <div className='about__page__wrapper'>
      <div className='about__page__wrapper__wrapper'>
        <HeaderGraphic words="What is Functional Medicine?" video="https://www.youtube.com/embed/F7vTWQ873LE" tagline="Root cause approach towards medicine" />
        <div className='general__page__content__wrapper'>
          <h2>Acute Care vs Chronic Care</h2>

          <p>Chronic disease is a serious issue that affects a significant portion of the US population. It’s alarming to know that 6 out of 10 adults are battling with a chronic disease, while 4 out of 10 are struggling with at least two. Unfortunately, the prevalence of chronic disease is on the rise, and it’s the leading driver of our nation’s staggering $4.1 trillion in annual healthcare costs.</p>

          <div className='floating__image__right'>
            <img src={stats} className='floating__right__image' alt="A diverse group of people smiling with the text 6 in 10 adults have 1 chronic disease, 4 in 10m adults have at least 2 chronic diseases"/>
            <p>We believe that chronic disease requires a different approach, one that goes beyond conventional medicine and cookie-cutter treatments. Functional medicine offers a cutting-edge approach that seeks to uncover the underlying causes of your symptoms. Instead of just containing the fire, we strive to understand why the fire started in the first place. We need to dig deep and truly understand your unique story to unravel the disease and develop a personalized plan for your care.</p>
            <p>As a functional medicine practice, we partner together with our clients to find the best path towards wellness. Let’s swim upstream and work together to uncover the root cause of your symptoms, so we can develop a tailored strategy for your unique needs. It’s time to prioritize your health and take control of your journey towards optimal wellness. The road to healing starts today.</p>
          </div>


          <div className='compare__wrapper'>
            <div className='compare__item'>
              <h3>Conventional Medicine</h3>
              <ul>
                <li>Disease-centered model</li>
                <li>Standardized approach without room for individuality</li>
                <li>Diagnosis-based system designed to manage symptoms</li>
                <li>Relies heavily on standard lab and diagnostic testing to make treatment decisions</li>
                <li>Pharmaceutical drugs, surgery</li>
                <li>Referral-based system of care</li>
                <li>Best for Acute issues</li>
              </ul>
            </div>

            <div className='compare__item'>
            <h3>Functional Medicine</h3>
              <ul>
              <li>Client-centered model</li>
                <li>Personalized approach looking to unique factors influencing health</li>
                <li>Root cause medicine looking to uncover the reason behind the symptoms</li>
                <li>Uses cutting-edge testing and analysis to determine underlying factors contributing to illness such as genetics, nutrition, hormones, microbiome, as well as lifestyle, and environment</li>
                <li>Nutrition, exercise, stress management, mindfulness, strategic supplements</li>
                <li>Systems biology approach looking at how the body is interconnected</li>
                <li>Best for Chronic issues</li>
              </ul>
            </div>

  
          </div>
          <h2>What We Treat</h2>
          <Collapsible trigger={["Allergies", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Allergies occur when the immune system overreacts to a harmless substance, such as pollen or dust, causing symptoms such as sneezing, itching, and congestion.
            </p>
            
        </Collapsible>
        <Collapsible trigger={["Arthritis" , <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Arthritis is a condition characterized by inflammation and pain in the joints, which can be caused by a variety of factors such as injury, infection, or autoimmune disorders.
            </p>
        </Collapsible>
        <Collapsible trigger={["Asthma", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Asthma is a chronic respiratory condition that causes inflammation and narrowing of the airways, leading to symptoms such as wheezing, coughing, and shortness of breath.
            </p>
        </Collapsible>
        <Collapsible trigger={["Attention Deficit Hyperactivity Disorder (ADHD)", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            ADHD is a neurodevelopmental disorder that affects attention, behavior, and executive functioning, causing symptoms such as hyperactivity, impulsivity, and inattention.

            </p>
        </Collapsible>



        <Collapsible trigger={["Autoimmune Diseases", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Autoimmune diseases occur when the immune system attacks healthy cells and tissues in the body, leading to inflammation and damage to organs and tissues.  Some examples include lupus, multiple sclerosis, and Hashimoto’s disease.

            </p>
        </Collapsible>

        <Collapsible trigger={["Cardiovascular Disorders", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Cardiovascular disorders refer to a group of conditions that affect the heart and blood vessels, including heart disease, stroke, and hypertension.

            </p>
        </Collapsible>

        <Collapsible trigger={["Chronic Fatigue Syndrome", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Chronic fatigue syndrome is a complex disorder characterized by extreme fatigue that is not improved by rest and can be accompanied by a range of other symptoms such as cognitive impairment, sleep disturbances, and pain.
            </p>
        </Collapsible>

        <Collapsible trigger={["Chronic Pain", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Chronic pain is pain that persists for longer than 12 weeks, often caused by injury, inflammation, or nerve damage, and can significantly impact quality of life.
            </p>
        </Collapsible>

        <Collapsible trigger={["Depression, Anxiety, or Bipolar Disorder", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            These are mental health conditions characterized by changes in mood, behavior, and emotions, which can significantly impact daily functioning and quality of life.
            </p>
        </Collapsible>

        <Collapsible trigger={["Digestive Disorders", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Digestive disorders refer to a group of conditions that affect the digestive system, including irritable bowel syndrome (IBS), inflammatory bowel disease (IBD), and celiac disease.
            </p>
        </Collapsible>

        <Collapsible trigger={["Fibromyalgia", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Fibromyalgia is a chronic disorder characterized by widespread pain, fatigue, and tenderness in the muscles and soft tissues.
            </p>
        </Collapsible>

        <Collapsible trigger={["Food Sensitivities", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Food sensitivities refer to adverse reactions to certain foods that do not involve the immune system, such as lactose intolerance or sensitivity to certain food additives. Symptoms may include bloating, abdominal pain, diarrhea, and skin rashes.
           It’s important to know that you may experience joint pain, headaches, brain fog, or even depression with food sensitivities as well. </p>
        </Collapsible>

        <Collapsible trigger={["Insomnia and Sleep Disorders", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Insomnia and sleep disorders are conditions characterized by difficulty falling asleep or staying asleep, leading to daytime fatigue and other health complications.
            </p>
        </Collapsible>

        <Collapsible trigger={["Metabolic Syndrome", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Metabolic syndrome refers to a cluster of conditions, including high blood pressure, high blood sugar, excess body fat around the waist, and abnormal cholesterol levels, that increase the risk of developing cardiovascular disease, stroke, and type 2 diabetes.
            </p>
        </Collapsible>

        <Collapsible trigger={["Skin Disorders", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Skin disorders refer to a range of conditions that affect the skin, including eczema, psoriasis, and acne.
            </p>
        </Collapsible>
        <Collapsible trigger={["Thyroid, Adrenal and Other Hormonal Disorders", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Hormonal disorders refer to a range of conditions that affect the endocrine system, including thyroid disorders, adrenal disorders, and polycystic ovary syndrome (PCOS).
            </p>
        </Collapsible>

        <Collapsible trigger={["Weight or Metabolism Concerns", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Weight and metabolism concerns refer to conditions related to weight management, metabolism, and nutrition, including obesity, metabolic syndrome, and type 2 diabetes.
            </p>
        </Collapsible>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default WIFM