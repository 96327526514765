import React from 'react'
import './Testimonials.css'

// Import Swiper React components
import { Swiper, SwiperSlide} from 'swiper/react';


// import required modules
import { Autoplay, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/css';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Testimonials = () => {
  const [swiper, setSwiper] = useState(null);
  return (
    
    <div className='testimonials__section'>
    <h2>Testimonials.</h2>
      <Swiper
        loop
        slidesPerView={4}
        centeredSlides={true}
        spaceBetween={30}
        
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        
        modules={[Pagination]}
        className="mySwiper"
        breakpoints={{
          "@0.00": {
            slidesPerView: 1.25,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          "@1.25": {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          "@1.50": {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          "@1.75": {
            slidesPerView: 3.2,
            spaceBetween: 30,
          },
          
        }}
      >
        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>Tara’s knowledge and the immense effort she puts into her patients is immeasurable.  She points out that there are underlying causes for health issues and is seeking ways to not just identify them but ways we can learn to navigate to prevent them if we can be aware of the root of the problem.  I not only was beyond grateful to meet her years ago but have followed her and will continue to follow her through her podcasts and the practice she will continue through it.</p>


          </div>
          <p className='author'>Jeanne S.</p>
        </SwiperSlide>

        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>Tara is meticulous in her care. I was recently diagnosed with osteoporosis, and my PCP sent me a letter basically saying, "Here, take this medication." Tara, however, saw an unexpected protein in my blood and immediately ordered a series of blood tests that revealed an underlying pre-cancer of some concern. If she had not taken the initiative, I might still not know. I trust her completely.</p>


          </div>
          <p className='author'>Whitney W.</p>
        </SwiperSlide>

        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>I believed that my best life was behind me.  I did not see how I had been running myself into the ground.  I always have put everyone else first.  I haven’t felt this energetic since I was in my 20’s. My friends tell me I look 10 years younger.  Plus, my crazy mood swings and hormone dysfunction are gone!  Working with Tara was one of the best decisions I have made regarding my health and I recommend her to everyone that I know!</p>


          </div>
          <p className='author'>Danielle P.</p>
        </SwiperSlide>

        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>When I met Tara for the first time, my defenses were up. I was prepared for yet another demoralizing visit with a healthcare practitioner where I would share what was happening with my body (sometimes in tears), and immediately be dismissed or told to take a prescription drug. I was instead shocked (finally in a good way!) when Tara listened to me, and asked questions to get a better understanding of what was happening.</p>


          </div>
          <p className='author'>Jennifer S.</p>
        </SwiperSlide>

        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>She has honest conversations that are validating – she will never tell you what you’re experiencing isn’t real. Through our work together I learned I have a second autoimmune disease.  We spent more than an hour together that day and began what I consider to be a true partnership in healing my body.  Tara treats each of her patients as an individual and helps them to feel empowered as they learn about what is causing their illness and how they can approach healing. She has changed my life.</p>


          </div>
          <p className='author'>Jackie T.</p>
        </SwiperSlide>

        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>Working with her has been such a powerful experience for me, my 17-year-old daughter is also her patient. I want my child to learn to trust herself and her body while she is young, I know Tara will teach her to do that.  I cannot recommend Tara enough; anyone who works with her will be forever changed for the better. I am so grateful for her knowledge, support, guidance, and genuine caring as she walks with me on this healing journey.</p>


          </div>
          <p className='author'>Elizabeth R.</p>
        </SwiperSlide>

        <SwiperSlide>
          <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>I met Tara on the onset journey of my battle with breast cancer. It was a very difficult and scary time in my life. Tara put me at ease by providing encouragement and guidance to what my next year would entail. She also went above and beyond equipping me with nutritional information and suggestions to help with inflammation due to an autoimmune disease. I truly would not have had the same experience without her in this battle.</p>


          </div>
          <p className='author'>Janine B.</p>
        </SwiperSlide>
        <SwiperSlide>
        <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>I had just about given up hope.  I had been battling with severe pain, brain fog, and also had blood sugar issues.  Tara took the time to truly listen, something I wasn’t expecting.  Through in depth testing we discovered gut dysbiosis, amongst other things.  Tara gave me the tools I needed to finally wake up every day feeling better!  What I loved is that if something wasn’t working, we changed course.  Highly recommend Tara and her practice!</p>


          </div>
          <p className='author'>Trisha P.</p>
        </SwiperSlide>
        <SwiperSlide>
        <span>“</span>
          <div className='swiper__text__wrapper'>
            <p>My hormones were a complete mess.  Sleep was an issue and I was having severe anxiety.  When Tara did my timeline it was eye opening!  I cried that day.  Tara guided me through this difficult time and even though it wasn’t easy, my symptoms began improving.  For the first time in I don’t know how long, I feel like myself again.  I never would have guessed that the foods I eat can affect my hormones!  Best decision I could have made was working with Tara.</p>


          </div>
          <p className='author'>Allison G.</p>
        </SwiperSlide>
      </Swiper>
      <div className="button-wrapper-testimonials">
        <button
          className="button"
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          className="button"
          onClick={() => {
            swiper.slideNext();
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  )
}

export default Testimonials