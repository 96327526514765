import React from 'react'
import HeaderGraphic from '../../components/HeaderGraphic/HeaderGraphic'
import Footer from '../../components/Footer/Footer'

const Media = () => {
  return (

    <div className='about__page__wrapper'>
        <div className='about__page__wrapper__wrapper'>
        <HeaderGraphic words="Podcast." video="https://www.youtube.com/embed/f31aHm4GUXI" tagline="Learn about your body and how to improve your health"/>
        <div className='general__page__content__wrapper'>
            <h2>Listen in</h2>

            <p>Conversations grounded in the latest evidence-based science, looking through a functional medicine lens. If you love learning about your body and how to improve your health - this is the podcast for you!</p>

            <div className='buttonArray'>
              <a href='https://open.spotify.com/show/3HMV5PH4NshZeWhAqq02vA' target='_blank'>Listen on Spotify</a>
              <a href='https://podcasts.apple.com/us/podcast/the-functional-nurse-practitioner/id1652908752' target='_blank'>Listen on Apple Podcasts</a>
            </div>

            <h2>Latest Episodes</h2>
            <iframe id="embedPlayer" src="https://embed.podcasts.apple.com/us/podcast/the-functional-nurse-practitioner/id1652908752?itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto" height="450px" frameborder="0" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *; clipboard-write" className='podcastembed' ></iframe>


            <div className='buttonArray buttonCenter'>
              <a href='https://forms.gle/QX4WXJNQQ7sGM79i6' target='_blank'>Interested in being a guest on our show?</a>
            </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Media