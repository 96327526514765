import React from 'react'
import './ShortHomeAbout.css'
import HeaderGraphic from '../HeaderGraphic/HeaderGraphic'
import Testimonials from '../Testimonials/Testimonials'
import { Link } from 'react-router-dom'

const ShortHomeAbout = () => {
  return (
    <div className='short__home__about__wrapper'>
      <div className='short__home__about__wrapper__wrapper'>
        <Testimonials />
        <div className='general__page__content__wrapper'>
          <div className='home__three__box'>
            <Link to="/what-is-functional-medicine"><div className='home__three__box__item'>
              <h3>Root cause medicine</h3>
              <p>Do you suffer from a chronic disease? Are you tired of feeling bad? Has traditional medicine left you feeling lost?</p></div></Link>
              <Link to="/membership"><div className='home__three__box__item'>
              <h3>Why memberships?</h3>
              <p>Our approach is highly individualized and tailored to each client’s specific needs, ensuring that they receive the best care possible.</p></div></Link>
              <Link to="/about"><div className='home__three__box__item'>
              <h3>As a practice</h3>
              <p>We take a holistic approach to healthcare and focus on you as a whole person, not just your symptoms and diseases.
              </p>
              </div></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortHomeAbout