import React from 'react'
import HeaderGraphic from '../../components/HeaderGraphic/HeaderGraphic'
import Footer from '../../components/Footer/Footer'
import Collapsible from 'react-collapsible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'

const Membership = () => {

  return (
    <div className='about__page__wrapper'>
        <div className='about__page__wrapper__wrapper'>
        <HeaderGraphic words="Membership." video="https://www.youtube.com/embed/VJqaBL--PBs" tagline="Let's get your health on track"/>
        <div className='general__page__content__wrapper'>
            <h2>Why You Should Partner With Us</h2>

            <p>As a Functional Medicine practice, we are dedicated to partnering with our clients to help them achieve optimal health by uncovering their unique stories. We believe that genetics, lifestyle, and mindset all play a vital role in our expression of disease. Unfortunately, conventional insurance-based care often fails to prioritize wellness over sickness. This is why, at our practice, our top priority is our practice members.</p>


            <p>Unlike typical practitioner offices, we don’t just band-aid symptoms. Instead, we use strategic Functional Medicine methodologies to identify and address the root causes of health issues, which allows for true healing. Our approach is highly individualized and tailored to each client’s specific needs, ensuring that they receive the best care possible.</p>
                
            <p>If you are interested in working with us, the first step is to book an initial introductory consultation. During this consultation, we will discuss your goals and what you have tried in the past to address your health concerns. This information will help us determine whether we are a good fit and whether we should partner together on this journey towards wellness.</p>

            <p>If we decide to move forward, we will schedule a 90-minute consultation where we will utilize powerful tools like the Functional Medicine Timeline and Matrix. This will enable us to gain a deeper understanding of your unique story, allowing us to put together a personalized action plan that addresses the underlying causes of your symptoms. Our goal is to provide you with all the necessary tools for optimal health, including guidance, mentorship, and coaching.</p>

            <p>Depending on your unique needs and goals, we may also consider functional medicine lab testing and strategic high-grade supplementation as part of your personalized action plan. We want to make sure that you have everything you need to achieve optimal health.</p>

            <p>At our practice, we believe that our bodies are capable of miraculous healing when we utilize holistic approaches to wellness. We spend more time with our clients than conventional practitioners, so we can truly understand the underlying causes of their symptoms and create personalized action plans based on the latest evidence-based science.</p>

            <p>As someone who has personally faced serious health challenges, I understand just how valuable this type of care can be. If you are struggling with chronic health issues or mystery symptoms, we can help. By working together, we can uncover the unique triggers and drivers of your health issues and embark on a journey towards optimal health.</p>

            <p>If you are ready to prioritize yourself and take control of your health, schedule your free introductory consultation with us today. You are the most important person in your life, and helping individuals like you to live their best lives is the most gratifying part of what we do. We are committed to partnering with you on your journey to optimal health.</p>

            <div className='home__three__box membership'>
            <div className='home__three__box__item'>
              <h3>A La Carte</h3>
              <p>A la carte may be a good choice if you want to address a health concern and have not yet decided on a membership option. This may be helpful for lab review or as an additional visit with your current membership.</p></div>
              <div className='home__three__box__item'>
              <h3>Basic Membership</h3>
              <p>Basic Memberships may be a good choice for individuals who are new to functional medicine but are looking for an affordable partnership incorporating general educational materials, resources, and support.</p></div>
              <div className='home__three__box__item'>
              <h3>Platinum Membership
</h3>
              <p>Platinum memberships may be a good choice for individuals with complex conditions who are looking for more extensive support, resources, and benefits of a closer partnership.  Individuals may greatly benefit from this expanded supportive approach to wellness, providing more accountability and motivation to make long-term changes to their health and lifestyle.
              </p>
              </div>
          </div>
          <Collapsible trigger={["Why Insurance-Based Models Aren't the Answer", <FontAwesomeIcon icon={faChevronCircleDown} /> ]}>
            <p>
            Insurance-based models of healthcare often prioritize treating illnesses rather than preventing them, which can lead to higher healthcare costs and poorer health outcomes in the long term. Additionally, these models can be very costly, particularly for people with pre-existing conditions or those who require frequent medical care.</p>
            
            <p>The insurance approval process can also delay or restrict access to certain types of care, leading to frustration and potentially compromising client outcomes. Furthermore, insurance-based models can sometimes incentivize practitioners to over-treat clients, as more treatments and procedures can mean higher reimbursement rates from insurance companies. This can lead to higher healthcare costs and potentially unnecessary treatments or procedures for patients.</p>
              
              <p>On the other hand, membership-priced models offer a more client-centered and cost-effective approach to healthcare. By charging a set fee for a specific period, these models eliminate financial barriers to accessing care, incentivize practitioners to focus on preventive care, and offer more personalized care. Additionally, these models often include a range of services, such as telemedicine, wellness coaching, and nutrition counseling, which can help clients achieve their long-term health goals. Overall, membership-priced models offer a more effective and client-centered approach to healthcare, while also providing more affordable and accessible care.
            </p>
        </Collapsible>
            </div>
           
        </div>
        <Footer />
    </div>
  )
}

export default Membership