import React from 'react'
import './Home.css'
import GlassCTA from '../../components/GlassCTA/GlassCTA'
import AnimatedBG from '../../components/AnimatedBG/AnimatedBG'
import Testimonials from '../../components/Testimonials/Testimonials'
import ShortHomeAbout from '../../components/ShortHomeAbout/ShortHomeAbout'
import Footer from '../../components/Footer/Footer'

const Home = () => {
  return (
    <div className='page__container'>
      
        <AnimatedBG />
        <div className='page__wrapper'>
            <GlassCTA />

        </div>

        
        <section className='home__intro__section'>
            <ShortHomeAbout />
        </section>
        <Footer />
    </div>
  )
}

export default Home