import './App.css';
import Navbar from './components/Navbar/Navbar';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WIFM from './pages/WIFM/WIFM';
import Membership from './pages/Membership/Membership';
import Media from './pages/Media/Media';
import { withRouter } from "react-router-dom";
import { useEffect } from 'react';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';




function App() {


  
  return (
    <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Routes>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/what-is-functional-medicine" element={<WIFM />} />
              <Route path="/membership" element={<Membership />} />
              <Route path="/podcast" element={<Media />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
